<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://i.pinimg.com/originals/1c/d8/47/1cd84761e613e417f08ea18f3586a884.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title" title="Software">Software</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="col-md-10 offset-md-1">
        <div class="page-content">
          <div>
            <ul class="entry-list">
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                <span class="title">
                  <b-link
                    href="http://tbs.com.my/ecommerce/download/TeamViewer_Setup.exe"
                    >TeamViewer
                  </b-link>
                </span>
              </li>
              <!-- <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a
                    href="http://tbs.com.my/ecommerce/download/csvRetread10ExpertQR_B160I47.exe"
                    >Carser For Retread Ver 10.0 B160
                  </a>
                </span>
              </li>
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a
                    href="http://tbs.com.my/ecommerce/download/csv10ExpertQ_B161I47.exe"
                    >Carser Expert Ver 10.0 B161
                  </a>
                </span>
              </li>
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a
                    href="http://tbs.com.my/ecommerce/download/csv10_B161frx.zip"
                    >Carser Report
                  </a>
                </span>
              </li>
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a
                    href="http://tbs.com.my/ecommerce/download/tbsacct81_B87I22.exe"
                    >Accounting Ver 8.10
                  </a>
                </span>
              </li>
              <li>
                <img
                  src="https://downloads.acs.com.hk/product-image-drivers/470-acos3-microprocessor-card-24x24.png"
                />
                &nbsp;&nbsp;&nbsp;
                <span class="title">
                  <a
                    href="http://tbs.com.my/ecommerce/download/TbsErp.TradingStock.9.0.16.0.Setup.exe"
                    >Trading Stock Ver 9.0 (Released Date : 11-5-2015)
                  </a>
                </span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Software"
};
</script>
